import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtki { font-style: italic; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .mtk9 { color: #F07178; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .mtk4 { color: #546E7A; }
  .material-theme .mtk8 { color: #82AAFF; }
  .material-theme .mtk5 { color: #FF9CAC; }
  .material-theme .mtk6 { color: #F78C6C; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/36bd1da897de85f73cec268f82077646/8ccd0/banner.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABzRzHwHDsX//EABgQAQEAAwAAAAAAAAAAAAAAAAECAxEx/9oACAEBAAEFApdQLRl0Xh7dJL3/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQMBAT8BjL//xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BrT//xAAZEAABBQAAAAAAAAAAAAAAAABhAAIgISL/2gAIAQEABj8CaVqxH//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBMXH/2gAIAQEAAT8hxmHUGnwpLAavsT15GIOOJUapWf/aAAwDAQACAAMAAAAQq+//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxBx1BDt/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQBBUf/aAAgBAgEBPxA1L5f/xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhYTFBUXHR/9oACAEBAAE/EBcy5Y7qv2WwB6w6V6mBYBh7BhtaAzx8h6jhCk2e7ioF3P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Automate Flutter Deployments to App Store and Play Store using Fastlane and Github Actions",
            "title": "Automate Flutter Deployments to App Store and Play Store using Fastlane and Github Actions",
            "src": "/static/36bd1da897de85f73cec268f82077646/e5166/banner.jpg",
            "srcSet": ["/static/36bd1da897de85f73cec268f82077646/f93b5/banner.jpg 300w", "/static/36bd1da897de85f73cec268f82077646/b4294/banner.jpg 600w", "/static/36bd1da897de85f73cec268f82077646/e5166/banner.jpg 1200w", "/static/36bd1da897de85f73cec268f82077646/8ccd0/banner.jpg 1792w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I find streamlining the release of Flutter apps to App Store / Play Store / testers to be a pain. There are so many moving parts and so much configuration. And the documentation is not always the best. Even though I have done it a few times - and can copy from older projects - I still find myself googling the same things over and over again.`}</p>
    <p>{`I find that despite there being a lot of tutorials on setting up CI/CD for Flutter apps, most of them are either outdated, too complicated, containg errors or are just not very clear.`}</p>
    <p>{`I wrote this article to provide a no-nonsense guide to setting up automatic release of your Flutter app using GitHub Actions and locally, using Fastlane, in the simplest and fastest way possible.`}</p>
    <p>{`This is less of a tutorial and more of a copy-paste guide. Because let's be real, who has time to read through a 10-page tutorial when you just want to get things done.`}</p>
    <h2>{`What you'll end up with`}</h2>
    <p>{`A Flutter app that is automatically built and deployed to the App Store (TestFlight) and Play Store every time you create a new release on GitHub. From there you can manually release the app to the public using the respective platforms.`}</p>
    <p><strong parentName="p">{`It also includes:`}</strong></p>
    <ul>
      <li parentName="ul">{`A fully functional Fastlane setup for both Android and iOS, managed entirely by configurable environment variables (making it easy to run locally, but also via deploy tools like Github Actions or other CI/CD tools)`}</li>
      <li parentName="ul">{`Automatic versioning of the app (based on the release tag)`}</li>
      <li parentName="ul">{`Automatically running `}<a parentName="li" {...{
          "href": "https://pub.dev/packages/build_runner"
        }}>{`build_runner`}</a>{` if your project uses it`}</li>
      <li parentName="ul">{`Support for `}<a parentName="li" {...{
          "href": "https://firebase.google.com/docs/crashlytics"
        }}>{`Firebase Crashlytics`}</a>{` if you are using it (or plan to use it), by `}<a parentName="li" {...{
          "href": "https://firebase.google.com/docs/crashlytics/get-deobfuscated-reports"
        }}>{`uploading the dSYMs`}</a></li>
      <li parentName="ul">{`Automatic code signing for iOS using `}<a parentName="li" {...{
          "href": "https://fastlane.tools/match/"
        }}>{`Fastlane Match`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/bartekpacia/fastlane-plugin-get_new_build_number"
        }}>{`Automatic incrementing build numbers`}</a>{` fetched from Google Play Console and App Store Connect`}</li>
      <li parentName="ul">{`Optionally using `}<a parentName="li" {...{
          "href": "https://firebase.google.com/docs/app-distribution"
        }}>{`Firebase App Distribution`}</a>{` on Android for distributing your app to testers`}</li>
      <li parentName="ul">{`Optionally sending Slack notifications when a build is successful or fails (if you provide a webhook URL)`}</li>
    </ul>
    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`You have a Flutter app that you want to deploy`}</li>
      <li parentName="ul">{`You have a developer account for the stores you wish to deploy to (App Store and/or Play Store)`}</li>
      <li parentName="ul">{`You have installed Fastlane on your machine - seems like many people from the community recommends just installing it via Homebrew (if you're on mac). Alternatively you can handle it via `}<a parentName="li" {...{
          "href": "https://github.com/rbenv/rbenv"
        }}>{`rbenv`}</a>{` or `}<a parentName="li" {...{
          "href": "https://rvm.io/"
        }}>{`rvm`}</a>{`. I'll leave it up to you to decide how you want to install it. You can find the `}<a parentName="li" {...{
          "href": "https://docs.fastlane.tools/#Getting-Started"
        }}>{`installation instructions for Fastlane here`}</a></li>
      <li parentName="ul">{`You have a GitHub repository for your Flutter app, if you wish to use GitHub Actions for automating the deployment`}</li>
    </ul>
    <h2>{`Example Flutter project`}</h2>
    <p>{`I have created a simple Flutter project based on this post. You can find it here: `}<a parentName="p" {...{
        "href": "https://github.com/constantsolutions/flutter_fastlane_tutorial"
      }}>{`constantsolutions/flutter_fastlane_tutorial`}</a>{`.`}</p>
    <p>{`I have seperated each commit, so you can see exactly what I did to set up the project. You can use this as a reference if you get stuck.`}</p>
    <h2>{`Bootstrapping the project in one command`}</h2>
    <p>{`I have created a simple Dart script that you can use to `}<a parentName="p" {...{
        "href": "https://gist.githubusercontent.com/simplenotezy/f52b470293edafa919584d911cc5e6b9"
      }}>{`bootstrap your Flutter project with the necessary Fastlane files`}</a>{`. If you have any existing fastlane configurations, you can delete them as this script will create new ones, or alternatively inspect the script and modify it to suit your needs.`}</p>
    <p><strong parentName="p">{`The script will create the following files:`}</strong></p>
    <ul>
      <li parentName="ul">{`android/fastlane/.env`}</li>
      <li parentName="ul">{`android/fastlane/Appfile`}</li>
      <li parentName="ul">{`android/fastlane/Fastfile`}</li>
      <li parentName="ul">{`android/fastlane/Pluginfile`}</li>
      <li parentName="ul">{`android/key.properties`}</li>
      <li parentName="ul">{`ios/fastlane/.env`}</li>
      <li parentName="ul">{`ios/fastlane/Appfile`}</li>
      <li parentName="ul">{`ios/fastlane/Fastfile`}</li>
      <li parentName="ul">{`ios/fastlane/Matchfile`}</li>
      <li parentName="ul">{`ios/fastlane/Pluginfile`}</li>
      <li parentName="ul">{`google_service_account.json`}</li>
    </ul>
    <p><strong parentName="p">{`The script will also:`}</strong></p>
    <ul>
      <li parentName="ul">{`Modify `}<strong parentName="li">{`android/app/build.gradle`}</strong>{` (to include the signing configuration)`}</li>
      <li parentName="ul">{`Read `}<strong parentName="li">{`ios/Runner.xcodeproj/project.pbxproj`}</strong>{` (to include the correct bundle identifier in `}<strong parentName="li">{`ios/fastlane/.env`}</strong>{`)`}</li>
      <li parentName="ul">{`Read `}<strong parentName="li">{`android/app/build.gradle`}</strong>{` (to include the correct package name in `}<strong parentName="li">{`android/fastlane/.env`}</strong>{`)`}</li>
      <li parentName="ul">{`Ensure that your `}<strong parentName="li">{`.gitignore`}</strong>{` contains sensitive files such as `}<strong parentName="li">{`.env`}</strong>{` and other files for your safety`}</li>
    </ul>
    <p>{`To run the bootstrap script, run the following command in the root of your Flutter project:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`curl -o bootstrap_fastlane.dart https://gist.githubusercontent.com/simplenotezy/f52b470293edafa919584d911cc5e6b9/raw `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`&&`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` dart run bootstrap_fastlane.dart`}</span></span></span></code></pre>
    <p>{`If you're curious, you can inspect the script `}<a parentName="p" {...{
        "href": "https://gist.githubusercontent.com/simplenotezy/f52b470293edafa919584d911cc5e6b9"
      }}>{`here`}</a>{`.`}</p>
    <h3>{`Fetching the secrets`}</h3>
    <p>{`The next step is to fill out the .env files in the `}<strong parentName="p">{`android/fastlane`}</strong>{` and `}<strong parentName="p">{`ios/fastlane`}</strong>{` directories with the necessary information.`}</p>
    <p>{`I'll assume you'll be wanting to deploy to both the App Store and Play Store. If you only want to deploy to one of them, you can skip the steps for the other platform.`}</p>
    <p>{`While you add each secret to the `}<strong parentName="p">{`.env`}</strong>{` files, make sure to also add them to your GitHub repository's secrets as well. You can do this by going to `}<strong parentName="p">{`Repository Settings -> Secrets -> New repository secret`}</strong>{`.`}</p>
    <h2>{`Play Store for Android builds`}</h2>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "hhttps://play.google.com/console/u/0/developers/create-new-app"
      }}>{`Google Play Console`}</a>{` and create a new app, if you haven't already.`}</p>
    <h3>{`Service Account JSON`}</h3>
    <p>{`The Fastfile is using `}<a parentName="p" {...{
        "href": "https://docs.fastlane.tools/actions/supply/#setup"
      }}>{`supply`}</a>{` to upload the application bundle (AAB) to the Play Store. They have an `}<a parentName="p" {...{
        "href": "https://docs.fastlane.tools/actions/supply/#setup"
      }}>{`excellent guide`}</a>{` on how to set up the necessary permissions and credentials - below is a condensed version.`}</p>
    <p>{`Here's a short summary:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://console.cloud.google.com/projectcreate/?hl=en"
        }}>{`Create a new Google Cloud Console project`}</a>{` (if you don't have one already).`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://console.cloud.google.com/apis/library/androidpublisher.googleapis.com"
        }}>{`Enable the Google Play Android Developer API`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://console.cloud.google.com/iam-admin/serviceaccounts/create"
        }}>{`Create a new Service Account`}</a>{` for the project, and copy the Email address (e.g.`}<strong parentName="li"><a parentName="strong" {...{
            "href": "mailto:fastlane-deployment@flutter-fastlane-tutorial.iam.gserviceaccount.com"
          }}>{`fastlane-deployment@flutter-fastlane-tutorial.iam.gserviceaccount.com`}</a></strong>{`). Give it `}<strong parentName="li">{`Firebase App Distribution Admin`}</strong>{` role if you wish to use Firebase App Distribution for distributing your app to testers. Otherwise click "Done".`}</li>
      <li parentName="ol">{`Open up the newly created service account by clicking on it in the list. Click "Keys", then "Add key" and choose "JSON". Copy the downloaded JSON key to the fluuter root directory and name it `}<strong parentName="li">{`google_service_account.json`}</strong>{`.`}</li>
      <li parentName="ol">{`Add the Serive Account JSON email in the `}<a parentName="li" {...{
          "href": "https://play.google.com/console/u/0/developers/users-and-permissions"
        }}>{`Google Play Console`}</a>{` under Users & permissions with the role `}<strong parentName="li">{`Admin (all permissions)`}</strong>{`.`}</li>
    </ol>
    <p>{`Now let's base64 encode the `}<strong parentName="p">{`google_service_account.json`}</strong>{` file, run the following command:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`base64 -i google_service_account.json `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`|`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` pbcopy`}</span></span></span></code></pre>
    <p>{`Add it to the GitHub secrets as `}<inlineCode parentName="p">{`FIREBASE_SERVICE_ACCOUNT_BASE64`}</inlineCode>{` (this is needed for Github Actions).`}</p>
    <h3>{`Firebase App Distribution (optional)`}</h3>
    <p>{`If you wish to use Firebase App Distribution for distributing your app to testers instead of directly to the Play Store, you'll need to fill in `}<inlineCode parentName="p">{`FIREBASE_APP_ID`}</inlineCode>{` in the `}<strong parentName="p">{`android/fastlane/.env`}</strong>{` file. You'll find the id in the `}<a parentName="p" {...{
        "href": "https://console.firebase.google.com/"
      }}>{`Firebase console`}</a>{` under `}<strong parentName="p">{`Project settings -> General -> Your apps -> App ID`}</strong>{`.`}</p>
    <p>{`Remember to enable it in Firebase here from the left menu under `}<strong parentName="p">{`Run -> App Distribution`}</strong>{`.`}</p>
    <p>{`Personally I prefer just to use TestFlight and Play Store and skip Firebase App Distribution, I find it to be an unnecessary step - let me know in the comments if you have a different opinion.`}</p>
    <h3>{`Generate a signing key for uploading to Play Store`}</h3>
    <p>{`Generate a signing key for your app. This key is used to sign the android app, which is required for uploading to the Google Play Store.`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`keytool -genkey -v -keystore `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`~`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`/key.jks -keyalg RSA -keysize 2048 -validity 10000 -alias release`}</span></span></span></code></pre>
    <p><em parentName="p">{`(You might need to install the Java Development Kit (JDK) to use the `}<inlineCode parentName="em">{`keytool`}</inlineCode>{` command, using brew run `}<inlineCode parentName="em">{`brew install openjdk`}</inlineCode>{`)`}</em></p>
    <p>{`You can use this snippet, to generate a random secure password for the keystore:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "3"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`openssl rand -base64 18 `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`|`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` tr -d `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span></code></pre>
    <p>{`Save the password you entered in the keytol in Github Actions as `}<inlineCode parentName="p">{`ANDROID_KEY_STORE_PASSWORD`}</inlineCode>{`.`}</p>
    <p>{`Copy it to `}<strong parentName="p">{`android/key.jks`}</strong>{`, and ensure that it is added to your .gitignore file:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "4"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`cp `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`~`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`/key.jks android/`}</span></span></span></code></pre>
    <p>{`Now edit the `}<strong parentName="p">{`android/key.properties`}</strong>{` file. Set storePassword and keyPassword to the `}<inlineCode parentName="p">{`ANDROID_KEY_STORE_PASSWORD`}</inlineCode>{` you created in the previous step.`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "properties",
      "data-index": "5"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`storePassword`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`<the password you used when generating the key>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`keyPassword`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`<the password you used when generating the key>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`keyAlias`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`release`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`storeFile`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`../key.jks`}</span></span></span></code></pre>
    <p>{`Now let's base64 encode the `}<inlineCode parentName="p">{`android/key.jks`}</inlineCode>{` file and add it to the GitHub secrets as `}<inlineCode parentName="p">{`ANDROID_KEYSTORE_FILE_BASE64`}</inlineCode>{`. Run the following command:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "6"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`base64 -i android/key.jks `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`|`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` pbcopy`}</span></span></span></code></pre>
    <p>{`If you're on Windows, ask `}<a parentName="p" {...{
        "href": "https://chat.openai.com"
      }}>{`ChatGPT`}</a>{` how to base64 encode a file without line breaks and without losing your sanity.`}</p>
    <h3>{`Modify the build.gradle file`}</h3>
    <p>{`Now modify your `}<strong parentName="p">{`android/app/build.gradle`}</strong>{`. If you used the bootstrap script, it should've done this for you, but if not, you can do it manually - or verify that it's correct.`}</p>
    <p>{`Above the `}<inlineCode parentName="p">{`android`}</inlineCode>{` block, ensure the following is present:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "gradle",
      "data-index": "7"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`def`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` keystoreProperties `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`Properties`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`()`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`def`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` keystorePropertiesFile `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` rootProject`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`file`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`key.properties`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`')`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` (keystorePropertiesFile`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`exists`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`) {`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    keystoreProperties`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`load`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`FileInputStream`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(keystorePropertiesFile)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`Ensure a `}<inlineCode parentName="p">{`signingConfigs`}</inlineCode>{` block is present and modify the `}<inlineCode parentName="p">{`release`}</inlineCode>{` build type to use the signing config:`}</p>
    <pre {...{
      "className": "grvsc-container grvsc-has-line-highlighting material-theme",
      "data-language": "gradle",
      "data-index": "8"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    signingConfigs {`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        release {`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`            keyAlias keystoreProperties`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`['`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`keyAlias`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`']`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`            keyPassword keystoreProperties`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`['`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`keyPassword`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`']`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`            storeFile keystoreProperties`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`['`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`storeFile`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`']`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`?`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` file`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`keystoreProperties`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`['`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`storeFile`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'])`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`null`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`            storePassword keystoreProperties`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`['`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`storePassword`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`']`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        }`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    }`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    buildTypes {`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        release {`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-del"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-del",
            "aria-hidden": "true",
            "data-content": "-"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`            `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// TODO: Add your own signing config for the release build.`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-del"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-del",
            "aria-hidden": "true",
            "data-content": "-"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`            `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// Signing with the debug keys for now, so \`flutter run --release\` works.`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-del"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-del",
            "aria-hidden": "true",
            "data-content": "-"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`            signingConfig `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` signingConfigs`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`debug`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`            signingConfig signingConfigs`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`release`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        }`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    }`}</span></span></span></code></pre>
    <h3>{`Testing locally`}</h3>
    <p>{`Hurray! You can test the Play Store deployment locally by running the following command in the `}<strong parentName="p">{`android`}</strong>{` directory:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "9"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`fastlane release_play_store`}</span></span></span></code></pre>
    <h4>{`Manually uploading the first build`}</h4>
    <p>{`If it's a brand new app you're deploying, you'll need to set up your app manually first by uploading at least one build to Google Play Store. See `}<a parentName="p" {...{
        "href": "https://github.com/fastlane/fastlane/issues/14686"
      }}>{`fastlane/fastlane#14686`}</a>{` for more info.`}</p>
    <ol>
      <li parentName="ol">{`Go to the `}<a parentName="li" {...{
          "href": "https://play.google.com/console/u/0/developers/?hl=en"
        }}>{`Google Play Console`}</a>{`, choose your app, and upload the first build manually.`}</li>
      <li parentName="ol">{`Go to Testing -> Internal testing -> Create a new release -> Drop app bundles here to upload`}</li>
      <li parentName="ol">{`If prompted for signing key, just let Google manage it for you.`}</li>
      <li parentName="ol">{`You can use `}<inlineCode parentName="li">{`0.0.0`}</inlineCode>{` as the release name.`}</li>
      <li parentName="ol">{`Run a fastlane build (`}<inlineCode parentName="li">{`fastlane build`}</inlineCode>{` from `}<strong parentName="li">{`android`}</strong>{` directory) to get the app bundle. You can upload the app bundle to the Play Store (`}<inlineCode parentName="li">{`/your-flutter-project/build/app/outputs/bundle/release/app-release.aab`}</inlineCode>{`).`}</li>
      <li parentName="ol">{`Publish the release`}</li>
    </ol>
    <p>{`After that, you can use Fastlane to upload the builds automatically.`}</p>
    <p>{`If you get the error `}<inlineCode parentName="p">{`Google Api Error: Invalid request - Only releases with status draft may be created on draft app`}</inlineCode>{` have a look at `}<a parentName="p" {...{
        "href": "https://github.com/fastlane/fastlane/discussions/18293"
      }}>{`this discussion`}</a>{`. You can also set the `}<inlineCode parentName="p">{`release_status`}</inlineCode>{` to `}<inlineCode parentName="p">{`draft`}</inlineCode>{` in the `}<inlineCode parentName="p">{`supply`}</inlineCode>{` action in the `}<inlineCode parentName="p">{`android/fastlane/Fastfile`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "grvsc-container grvsc-has-line-highlighting material-theme",
      "data-language": "ruby",
      "data-index": "10"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`supply`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`track:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`internal`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`release_status:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`draft`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`aab:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`../build/app/outputs/bundle/release/app-release.aab`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`",`}</span></span></span></code></pre>
    <h3>{`Summary`}</h3>
    <p>{`You can now automatically publish your Flutter app to the Play Store by running the `}<inlineCode parentName="p">{`fastlane release_play_store`}</inlineCode>{` command in the `}<strong parentName="p">{`android`}</strong>{` directory or by creating a new release on GitHub. Continue reading to set up the App Store deployment, and learn how the GitHub Actions workflow file works.`}</p>
    <h2>{`App Store for iOS builds`}</h2>
    <p>{`We'll be using `}<a parentName="p" {...{
        "href": "https://docs.fastlane.tools/actions/match/"
      }}>{`Match`}</a>{` to manage the code signing for iOS. Match is a tool that allows you to share code signing certificates and provisioning profiles across your team (or across your CI/CD pipeline and your local machine).`}</p>
    <h3>{`Step 1: Create/retrieve your App bundle ID`}</h3>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "https://developer.apple.com/account/resources/identifiers/list"
      }}>{`Apple Developer Console`}</a>{` and create a new App ID. You can find the App ID in the `}<strong parentName="p">{`Identifiers`}</strong>{` section (select `}<strong parentName="p">{`App IDs -> App`}</strong>{` when asked what type of identifier you want to create).`}</p>
    <p><strong parentName="p">{`Tip: If you used the bootstrap script, check the `}<inlineCode parentName="strong">{`ios/fastlane/.env`}</inlineCode>{` to find your APP_BUNDLE_ID`}</strong></p>
    <p>{`Make sure your App ID is in the format `}<inlineCode parentName="p">{`com.yourcompany.yourapp`}</inlineCode>{` and that it matches the `}<inlineCode parentName="p">{`PRODUCT_BUNDLE_IDENTIFIER`}</inlineCode>{` from your `}<strong parentName="p">{`ios/Runner.xcodeproj/project.pbxproj`}</strong>{` file.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ec6f2d58b4c879f6f1c07eb7a74e1260/263f4/register_apple_id.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABMklEQVQoz31RW2rDMBDU/W9TeoTS30Bih+SndiFt49aWbb3fmSLbcUMIHRhJK8RodpYcj0fsdjuUZYnD4YBcb7dbFEWBuq6x3++x2WxQVRUyLpfLQ8Y0k0ilwYUE5xxSznvf9xiGAUKI6S7X+f6RIHDlDFKfznj/aDCOI5qmgbUW/2EVW2oXEqSLeC4HvFYchHYdxsUNpRTOuelhSukB4yI6C+eD9QnSRjwVPV7eOEhPKYzWCN7DOwdrNKw1t56mlTOGr3MDxgVijOsn9yCnpsPnN8UgDIQJEMZD2gDlEpSLEDYiJsBohZ9uAB0YpBTQWq+C2WyICTElkG4UaNoebc8wSrMyi5mlnSyYXed4cixt24Ixtg5RKTWJ5xjI34Ry2Am48g4hhCnfW+YBZnrv15Z/Aa0Ha5TDBo6GAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Github Secrets",
            "title": "Github Secrets",
            "src": "/static/ec6f2d58b4c879f6f1c07eb7a74e1260/c1b63/register_apple_id.png",
            "srcSet": ["/static/ec6f2d58b4c879f6f1c07eb7a74e1260/5a46d/register_apple_id.png 300w", "/static/ec6f2d58b4c879f6f1c07eb7a74e1260/0a47e/register_apple_id.png 600w", "/static/ec6f2d58b4c879f6f1c07eb7a74e1260/c1b63/register_apple_id.png 1200w", "/static/ec6f2d58b4c879f6f1c07eb7a74e1260/d61c2/register_apple_id.png 1800w", "/static/ec6f2d58b4c879f6f1c07eb7a74e1260/263f4/register_apple_id.png 1996w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Set the `}<inlineCode parentName="p">{`APP_BUNDLE_ID`}</inlineCode>{` variable in your `}<strong parentName="p">{`.env`}</strong>{` file and Github Actions Secrets to this value.`}</p>
    <h3>{`Step 2: Create a personal GitHub access token`}</h3>
    <p>{`Create a classic personal access token in your GitHub account, this is used by Match (in the next step) to access the repository where the certificates and profiles are stored.`}</p>
    <p>{`You can do this by going to `}<a parentName="p" {...{
        "href": "https://github.com/settings/tokens/new"
      }}>{`GitHub -> Settings -> Developer settings -> Personal access tokens`}</a>{`.`}</p>
    <p>{`Select the `}<strong parentName="p">{`repo`}</strong>{` scope, and copy the token. Now convert the token to a base64 string by running the following command in your terminal:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "11"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk8"
            }}>{`echo`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` -n `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`your_github_username:your_personal_access_token`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`|`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` base64 `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`|`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` pbcopy`}</span></span></span></code></pre>
    <p>{`The base64 string will be copied to your clipboard. Set the `}<inlineCode parentName="p">{`MATCH_GIT_BASIC_AUTHORIZATION`}</inlineCode>{` variable in your `}<strong parentName="p">{`ios/fastlane/.env`}</strong>{` (and Github Actions Secrets) file to this value.`}</p>
    <h3>{`Step 3: Generate certificates and provisioning profiles`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/new?name=app-certificates-and-profiles"
      }}>{`Create a private GitHub repository`}</a>{` to store your certificates and provisioning profiles. You can call it `}<strong parentName="p">{`app-certificates-and-profiles`}</strong>{` or something similar.`}</p>
    <p>{`Once created, add the github repository URL to your `}<inlineCode parentName="p">{`ios/fastlane/Matchfile`}</inlineCode>{` (make sure it ends on `}<inlineCode parentName="p">{`.git`}</inlineCode>{`):`}</p>
    <pre {...{
      "className": "grvsc-container grvsc-has-line-highlighting material-theme",
      "data-language": "ruby",
      "data-index": "12"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-del"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-del",
            "aria-hidden": "true",
            "data-content": "-"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`git_url`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`("`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`https://github.com/your/app-certificates-and-profiles.git`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`")`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`git_url`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`("`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`https://github.com/constantsolutions/app-certificates-and-profiles.git`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`")`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`storage_mode`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`("`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`git`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`")`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`("`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`appstore`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`")`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`# The default type, can be: appstore, adhoc, enterprise or development`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`app_identifier`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`([`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`ENV[`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`APP_BUNDLE_ID`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`])`}</span></span></span></code></pre>
    <p>{`Then run `}<inlineCode parentName="p">{`fastlane match appstore`}</inlineCode>{` from the `}<strong parentName="p">{`ios`}</strong>{` folder, to generate the certificates and profiles. This will automatically create a new certificate and provisioning profile for your app, and store them in the repository you specified.`}</p>
    <p>{`Note down the passphrase you used when running the `}<inlineCode parentName="p">{`match`}</inlineCode>{` command. Set the `}<inlineCode parentName="p">{`MATCH_PASSWORD`}</inlineCode>{` variable in your `}<strong parentName="p">{`.env`}</strong>{` file and Github Actions Secrets to this value.`}</p>
    <p><em parentName="p">{`Tip: You can use Fastlane Credentials Manager to store your login information which you'll be prompted for several times during the process. This is only needed when running locally. Run `}<inlineCode parentName="em">{`fastlane fastlane-credentials add --username your-developer-apple-id@gmail.com`}</inlineCode>{` to store your login information. You can then run `}<inlineCode parentName="em">{`FASTLANE_USER=your-developer-apple-id@gmail.com fastlane match appstore`}</inlineCode>{` without being prompted for your login information.`}</em></p>
    <h3>{`Step 4: Create API keys for App Store Connect`}</h3>
    <p>{`Go to `}<a parentName="p" {...{
        "href": "https://appstoreconnect.apple.com/access/integrations/api"
      }}>{`App Store Connect -> Users and Access -> Integrations`}</a>{` and create a new `}<strong parentName="p">{`Team Key`}</strong>{` with the `}<strong parentName="p">{`App Manager`}</strong>{` role.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/855fe437936088be1ccf115b8b47027b/388e7/app_store_connect_api_keys.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3VBqP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABcQAQEBAQAAAAAAAAAAAAAAAAEQACH/2gAIAQEAAT8hTmKz/9oADAMBAAIAAwAAABCwz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAAMBAQEAAAAAAAAAAAAAAAERIQAQMf/aAAgBAQABPxAQBB3MBFMCx7jdCXP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "App Store Connect API Keys",
            "title": "App Store Connect API Keys",
            "src": "/static/855fe437936088be1ccf115b8b47027b/e5166/app_store_connect_api_keys.jpg",
            "srcSet": ["/static/855fe437936088be1ccf115b8b47027b/f93b5/app_store_connect_api_keys.jpg 300w", "/static/855fe437936088be1ccf115b8b47027b/b4294/app_store_connect_api_keys.jpg 600w", "/static/855fe437936088be1ccf115b8b47027b/e5166/app_store_connect_api_keys.jpg 1200w", "/static/855fe437936088be1ccf115b8b47027b/d9c39/app_store_connect_api_keys.jpg 1800w", "/static/855fe437936088be1ccf115b8b47027b/388e7/app_store_connect_api_keys.jpg 2324w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Set the `}<inlineCode parentName="p">{`ASC_KEY_ID`}</inlineCode>{`, `}<inlineCode parentName="p">{`ASC_ISSUER_ID`}</inlineCode>{` variables in your `}<strong parentName="p">{`.env`}</strong>{` file and Github Actions Secret to the values displayed on the key page.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`ASC_KEY_P8_BASE64`}</inlineCode>{` value should be the contents of the .p8 file, base64 encoded. Download the key, and run the following command in your terminal, to base64 encode the key and copy it to your clipboard:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "13"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`cat `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`~`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`/Downloads/AuthKey_KEY_ID_HERE.p8 `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`|`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` base64 `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`|`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` pbcopy`}</span></span></span></code></pre>
    <h3>{`Step 5: Create a new app in App Store (optional)`}</h3>
    <p>{`If you haven't already, create a new app in App Store Connect. You can do this by going to `}<a parentName="p" {...{
        "href": "https://appstoreconnect.apple.com/apps"
      }}>{`App Store Connect -> My Apps -> (+) New App`}</a>{`.`}</p>
    <h3>{`Step 6: Adjust signing for release mode`}</h3>
    <p>{`We now need to adjust the code signing settings for the `}<inlineCode parentName="p">{`release`}</inlineCode>{` mode, so Xcode uses the certificates and provisioning profiles we just created with Match.`}</p>
    <ol>
      <li parentName="ol">{`Open XCWorkspace in Xcode (`}<inlineCode parentName="li">{`open ios/Runner.xcworkspace`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Select the Runner project in the left sidebar, and select the "Runner" target in the main window.`}</li>
      <li parentName="ol">{`Go to the "Signing & Capabilities".`}</li>
      <li parentName="ol">{`Go to "Release" and untick "Automatically manage signing".`}</li>
      <li parentName="ol">{`From Provisioning Profile you might need to click "Download" to download the profile.`}</li>
      <li parentName="ol">{`Select the "match AppStore `}{`[your app identifier]`}{`" profile.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8f1a6c75f929ac99bf5336c97083aad9/ba715/xcode_signing_profiles.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACuklEQVQ4y6WR227TQBCG9wIeAIFIk+LW61Nsx3biOHGapmmcxPUpdptDl5YeUwmB4I73bMT70OwOWgsQXCAuuPg02p3dX//8g5a9zssiPnw7TUMSjEYkGAckGB0TQs7JNBiQfs8jxXxBkiQlWZqQOI5IlmUkz2ckTRMSBAHp9/tkODw67/X8F2hwMMnmyzPIz2aQpSnk+Qyy2Qw6Xhs6xxn0oxV4XR86ngvdbgdctwW+34WDg155braaYJomtFpN0PX6FNluu1hdr+H29uZpsZjT1WpBk2xGjUaDmnaTuoOIuoMTatguNQydWpZFTdOkmqaW1Osa1fX6FmMRdnYqMXIss0iSGN5/+LAlZMXSNGHD4RGzHZvpqshkWWKe32du22ONhsHCMGS+77N6XWOqqjBFkXmlkoS5YIRs2yryPIf1+p5ysSg6gVEQgN0ZgmT6IMgGYFEAWZaAfzJNAxzHLlFVhd8xjEX6y6FtOUWWJ3Bzd0WTJGFhOIFRMAY3/Qh7ky9QcXKQsQC2bYNlNaDZdMocbdsCw6iDruvc5U+HMXIcu4iTGO7X9/T66h27vLyA8TgAca8GKt4FvF8DTVPLBfR6PkynU+BThOEUguAY+oMha1gOVRQJKhUu2GoWSXQCD3d39OFhzW6ur2EyGYMoioAlCTDGoChyuUXPa5fCfMudjlc6dRyH50n5m0rldYwsyyrm81P4/Okjvby8YEWRw2h0DDwTPgavf4P3eYaShH8budnK4yiE5fz0W5Zl2zCcbofDo60o7v8CY7FEkvBWUeQ/kGWJ955+LCVC7bY7T9ME0jQFXrlDnpOqadBomKBq6k8n/6RaraZIVdXD2Sz7ulwuHk9Pi8flYr6JkmxTFfBG3N/bCIKwqdVqm93dv/JYq1U3gvDmsVrd6SKE0HOE0CuEUOU/2Pmh8ew7I1NeUYLwfnkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Xcode Signing Profiles for Release",
            "title": "Xcode Signing Profiles for Release",
            "src": "/static/8f1a6c75f929ac99bf5336c97083aad9/c1b63/xcode_signing_profiles.png",
            "srcSet": ["/static/8f1a6c75f929ac99bf5336c97083aad9/5a46d/xcode_signing_profiles.png 300w", "/static/8f1a6c75f929ac99bf5336c97083aad9/0a47e/xcode_signing_profiles.png 600w", "/static/8f1a6c75f929ac99bf5336c97083aad9/c1b63/xcode_signing_profiles.png 1200w", "/static/8f1a6c75f929ac99bf5336c97083aad9/d61c2/xcode_signing_profiles.png 1800w", "/static/8f1a6c75f929ac99bf5336c97083aad9/ba715/xcode_signing_profiles.png 2210w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Step 6: Test the deployment`}</h3>
    <p>{`You can now test the deployment to the App Store by running the following command in the `}<strong parentName="p">{`ios`}</strong>{` directory:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "14"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`fastlane release_app_store`}</span></span></span></code></pre>
    <p>{`Hopefully, you'll see your app being uploaded to App Store Connect. It will take a few minutes for the build to be processed and before it'll be available for testing.`}</p>
    <p>{`From here, if you're happy with the result, you can release the app to the public.`}</p>
    <h2>{`Deploying with GitHub Actions`}</h2>
    <p>{`If you used the bootstrap script, you should have the necessary files in your project, otherwise you can create it manually (see `}<a parentName="p" {...{
        "href": "#the-github-actions-workflow-file"
      }}>{`"The GitHub Actions workflow file"`}</a>{`)`}</p>
    <p>{`The Flutter version used by the GitHub Actions runner is specified in the `}<strong parentName="p">{`pubspec.yaml`}</strong>{` file under the `}<a parentName="p" {...{
        "href": "https://github.com/constantsolutions/flutter_fastlane_tutorial/commit/5b7fb255f98eb73291e1440bde10354c6ccc2fa4#diff-8b7e9df87668ffa6a04b32e1769a33434999e54ae081c52e5d943c541d4c0d25R25"
      }}>{`environment section`}</a>{`, so make sure to add/modify it.`}</p>
    <h3>{`Create a new release`}</h3>
    <p>{`Go to your GitHub repository, and click on the "Releases" tab. Click on "Draft a new release", and fill in the necessary information.`}</p>
    <p>{`Press "Choose a tag" and give it a version number (e.g. `}<inlineCode parentName="p">{`1.0.0`}</inlineCode>{`). You can also add a title and description if you wish.`}</p>
    <p>{`Once you're ready, click "Publish release". You should see the GitHub Actions workflow kick in and start building and deploying your app.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c20c6ef1852279dcbe4e8abe6b20cbe3/891d5/github_release.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "152.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAACXBIWXMAABYlAAAWJQFJUiTwAAAE8klEQVRIx7VVW29UVRTe4Q8YfdDWMrczM+d+zpzLnNtc2plpCxTaQmmtpH0QA14SIsH4aIRgMfLmg38Ajgn8BB80UUkmPhg0EonBS1KcEMVEDBoN9czkM2vPpSAQ8cFJvnxrr733N2vvvdY6rLHnNCvo+47Xp+avqDOrl2YPbbRbz260p1ZOt5urZJ9pBwuvt6XwUFuwFtuCfaCdtRbbOWt/W3CW2mlz4ZJcee5KqXH0+MHX3mfsK4DZrWMXmstvIFo5hdbaO5hdfxuza2cwu/YWmqtvor58Cv7cCQTLGwj2n0RteQP1lQ0EiyfRWDnFbXPmxIWPAMYAMDVYOmc1j6A+/8odt3W4602vd8OZ9a4z+UzXqCx1TUJ1uWvPvtC1m4e74Z6Xu97s0a7TfL5bmXvxTnPxGFT/wDnSYu99coONTWTibFFFfXouMZwAtl+H5ddhOBFEzcZYKo/xdB7jO7MYT+UwNpHF2M4s0oKIVEFLdk3vhq4ZMRe88PHPbGwiG0uyDkU1kkxBhVoKkClokIwyNDtCUXWQFQ1u5yQTsulDtUJkRRNKyU8UK0RKUOIfSfAmwDIFMzbCBejRwaRoVpGXTQhSCYJkIi/3uY+BTb5tf0J/mBbkeJMEvwVYWpuM/fV3UTkSJ9rkGgyrjGzRQLaoc+REo4+hPeC+oJEQp3JS/PltMHaNBPNKLOkuFMNNRN3jR1JKhICzqLkQdRe0ps9lFBRrFCGdggSv/AnG/gJYTnZjd2o/KrtWE6e+AK82i2prH2rT85wtfxJO1IRbaXGQXVTtYfQJRUuClylCOncmL8e6HUI2yolkuPwxKAqKiGzZ8EZMoCgLqs1Fi6o9usNv6A47XFCJKQrdqSSaFUKzQ9zD/wT57WiIhDhbUONfSPDLP8DSghRrVgDF9JOSV4dZrsFwqyCb2HBrIx+xWe777WCKriMhmwRvk+DXCQnKsWoFfNING9DtCAXF5vknag7nPuyRTcemcUGxk6LmgE55gwQ/+xWMLtR0q4imdidBfQZO2OCVQtdAUZS8Gh8T28EkbH8SpXINedmih+GvTILXSfCHwR1SotJk9q4cG+XfQzBYc6/g5kCQnDS5XRWPhvsEr//fgv92zAfgwYKDUuIR5nlTGGAwHvnvtxPaO3rly7/TK8sx1a9RriXUlnS3ClEvQzQ8SnbeqqiuyU/rMkUdGd48DOSkUiLIFtKCEl8bdux8UYqj0EO96ifVqIx6NUCt6nPmdsVHteJx7o89Pl+NPHiOnri2AdLASLBAgmUuWInKfYGoLzC0K2F55KM19Ad3CwoFsS/4E2+waqyUQshmkKhWBN2tbcPpl95wrN01pj2qFfE9pEHNmlFBUx3SPalWwAud6pXq+B5QdQxsqqBSv5kMmkPIa/kWCV67028O9GFyo2biRg1i3kApHYa4r2Nvd/Ltjv0bGPviJlgqK54XFReK7m9JqtdTzKBX1JxeUbV7ou72JN3t5eVST5DMB2FrIHj+6ha1LzqyqVysvTSDmVf3onFiF+y5GmqNeVRae3n7H36sHgqF0ka++OF3YIyScULIny0G5i0xMjfFyOwIht4pKlanoJQ6mbzSSeWkTkp4CHLSZlqQbz2dEs5+T3f4eOapHYyxccaYyxjzGWPef4Q/2Dv+2BNP7mDanoh9CvB8pB8G9qNiuOeDq1tMMgP2NwXByU57Bay0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Github Secrets",
            "title": "Github Secrets",
            "src": "/static/c20c6ef1852279dcbe4e8abe6b20cbe3/c1b63/github_release.png",
            "srcSet": ["/static/c20c6ef1852279dcbe4e8abe6b20cbe3/5a46d/github_release.png 300w", "/static/c20c6ef1852279dcbe4e8abe6b20cbe3/0a47e/github_release.png 600w", "/static/c20c6ef1852279dcbe4e8abe6b20cbe3/c1b63/github_release.png 1200w", "/static/c20c6ef1852279dcbe4e8abe6b20cbe3/891d5/github_release.png 1520w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your Github Secrets should look something like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/077bde16331b38ea029b526500f3ea79/d1882/github_secrets.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABqElEQVQ4y41TyXLbMBTTpzSLZGuhuEkkZcvLJPH04C/pudOZ/j06eDKbHGzHB0gHSngAHljE3QlEmD/gpyOeVz1e1vo+ao2nFwX1M+L094yP32ec/pwx/3pHMWzehFD5LVo7oTHpP1qb0Jrr4LmJBwybd/R+hnIbdHZCwYdPR5iwhwk79MMWLh7Q6ChqXmtzFTxbdQP0OKNqParOy7tYqUGU1H1AayI6N8k0/vRUKYngGnjGb+p+XAY3Fq+1RUESKqJKEjUmygB+dIssg4S934q6snEoG4tirUaZQutV6/Cj7ATfkX1VKaBCEjIr5TeShUsHrDr/2KYvDtZqECEl0TgUzI429TCj1uEhZXlgowM6m0RhmRWSkHbzIvLHt8n0J6GJkuGizi4Z0jKnkJR2H1mGENZmWaBJst0qW17LQhKUm2DjXjJ5lozMA5YvCi92F8s6SH4sNg8JM+6gx90lgtsLIREXSst5MUttdJBi880OkpTKv7PNm8HFLNXJxbYJNuyl2C4dxTKt5PrcA/Nj1T5rY1HwR95JlpvXMBf73rXLIAEFfL3j/wBPbcipNeoIZgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Github Secrets",
            "title": "Github Secrets",
            "src": "/static/077bde16331b38ea029b526500f3ea79/c1b63/github_secrets.png",
            "srcSet": ["/static/077bde16331b38ea029b526500f3ea79/5a46d/github_secrets.png 300w", "/static/077bde16331b38ea029b526500f3ea79/0a47e/github_secrets.png 600w", "/static/077bde16331b38ea029b526500f3ea79/c1b63/github_secrets.png 1200w", "/static/077bde16331b38ea029b526500f3ea79/d1882/github_secrets.png 1562w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The workflow file also supports Slack notifications. If you wish to receive notifications on Slack, you can add a `}<a parentName="p" {...{
        "href": "https://api.slack.com/messaging/webhooks"
      }}>{`webhook URL`}</a>{` to the `}<inlineCode parentName="p">{`SLACK_LOGS_WEBHOOK`}</inlineCode>{` secret.`}</p>
    <h3>{`The GitHub Actions workflow file`}</h3>
    <p>{`The following is the GitHub Actions workflow file that you can use to automate the deployment of your Flutter app:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "yaml",
      "data-index": "15"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Publish iOS and Android release`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk5"
            }}>{`on`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`release`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`types`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`published`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`]`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`FLUTTER_CHANNEL`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`stable`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`RUBY_VERSION`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`3.2.2`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`jobs`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`build_ios`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Build iOS`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`# You can upgrade to more powerful machines if you need to`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`# See https://docs.github.com/en/actions/using-github-hosted-runners/about-larger-runners/about-larger-runners#about-macos-larger-runners`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`runs-on`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`macos-latest`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`# Depending on how long your build takes, you might want to increase the timeou`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`timeout-minutes`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`20`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`steps`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Checkout`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`actions/checkout@v4`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Set up Ruby`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ruby/setup-ruby@v1`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`with`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`ruby-version`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ env.RUBY_VERSION }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`bundler-cache`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`working-directory`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ios`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Run Flutter tasks`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`subosito/flutter-action@v2.16.0`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`with`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`# Remember to specify flutter version in pubspec.yaml under environment`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`# https://github.com/subosito/flutter-action?tab=readme-ov-file#use-version-from-pubspecyaml`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`flutter-version-file`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`pubspec.yaml`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`channel`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ env.FLUTTER_CHANNEL }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`cache`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`maierj/fastlane-action@v3.1.0`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`with`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`lane`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`release_app_store`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`subdirectory`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ios`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`options`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`{ "version_number": "\${{ github.ref_name }}" }`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`ASC_KEY_ID`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.ASC_KEY_ID }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`ASC_ISSUER_ID`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.ASC_ISSUER_ID }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`ASC_KEY_P8_BASE64`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.ASC_KEY_P8_BASE64 }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`MATCH_PASSWORD`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.MATCH_PASSWORD }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`MATCH_GIT_BASIC_AUTHORIZATION`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.MATCH_GIT_BASIC_AUTHORIZATION }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`APP_BUNDLE_ID`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.APP_BUNDLE_ID }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`notify_ios`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Send Slack Notification about iOS build`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`needs`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`build_ios`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`]`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`runs-on`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ubuntu-latest`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`timeout-minutes`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`2`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`steps`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Send Slack Notification about iOS build`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`rtCamp/action-slack-notify@v2`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ !cancelled() && (success() || failure()) && env.SLACK_LOGS_WEBHOOK_PRESENT == 'true' }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_LOGS_WEBHOOK_PRESENT`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.SLACK_LOGS_WEBHOOK && 'true' || 'false' }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_WEBHOOK`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.SLACK_LOGS_WEBHOOK }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_CHANNEL`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`logs`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_USERNAME`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ github.repository_owner }}`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_ICON`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`https://github.com/\${{ github.repository_owner }}.png?size=250`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_COLOR`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ contains(needs.*.result, 'success') && 'good' || 'danger' }}`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_TITLE`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ contains(needs.*.result, 'success') && 'Successfully released' || 'Error during release of' }} \${{ github.ref_name }} for iOS to TestFlight`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_FOOTER`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`DevOps`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_MESSAGE`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ contains(needs.*.result, 'success') && 'Released:' || 'Release failed:' }} \${{github.event.head_commit.message}}`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`build_android`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Build Android`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`runs-on`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ubuntu-latest`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`timeout-minutes`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`20`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`steps`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Checkout`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`actions/checkout@v4`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Set up Ruby`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ruby/setup-ruby@v1`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`with`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`ruby-version`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ env.RUBY_VERSION }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`bundler-cache`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`working-directory`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`android`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Run Flutter tasks`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`subosito/flutter-action@v2.16.0`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`with`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`flutter-version-file`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`pubspec.yaml`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`channel`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ env.FLUTTER_CHANNEL }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`cache`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Create google_service_account.json`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`run`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`|`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`          echo "\${{ secrets.FIREBASE_SERVICE_ACCOUNT_BASE64 }}" | base64 --decode > google_service_account.json`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Create key.jks`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`run`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`|`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`          echo "\${{ secrets.ANDROID_KEYSTORE_FILE_BASE64 }}" | base64 --decode > android/key.jks`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Create key.properties`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`run`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`|`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`          cat <<EOF > android/key.properties`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`          storePassword=\${{ secrets.ANDROID_KEY_STORE_PASSWORD }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`          keyPassword=\${{ secrets.ANDROID_KEY_STORE_PASSWORD }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`          keyAlias=release`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`          storeFile=../key.jks`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`          EOF`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`ANDROID_KEY_STORE_PASSWORD`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.ANDROID_KEY_STORE_PASSWORD }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`maierj/fastlane-action@v3.1.0`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`with`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`lane`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`release_play_store`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`subdirectory`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`android`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`options`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`{ "version_number": "\${{ github.ref_name }}" }`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`APP_PACKAGE_NAME`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.APP_PACKAGE_NAME }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`notify_android`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Send Slack Notification about Android build`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`needs`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`build_android`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`]`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`runs-on`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ubuntu-latest`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`timeout-minutes`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`2`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`steps`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`Send Slack Notification about Android build`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`uses`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`rtCamp/action-slack-notify@v2`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ !cancelled() && (success() || failure()) && env.SLACK_LOGS_WEBHOOK_PRESENT == 'true' }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_LOGS_WEBHOOK_PRESENT`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.SLACK_LOGS_WEBHOOK && 'true' || 'false' }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_WEBHOOK`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ secrets.SLACK_LOGS_WEBHOOK }}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_CHANNEL`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`logs`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_USERNAME`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ github.repository_owner }}`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_ICON`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`https://github.com/\${{ github.repository_owner }}.png?size=250`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_COLOR`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ contains(needs.*.result, 'success') && 'good' || 'danger' }}`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_TITLE`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ contains(needs.*.result, 'success') && 'Successfully released' || 'Error during release of' }} \${{ github.ref_name }} for Android to Play Store`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_FOOTER`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`DevOps`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`SLACK_MESSAGE`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`\${{ contains(needs.*.result, 'success') && 'Released:' || 'Release failed:' }} \${{github.event.head_commit.message}}`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span></span></span></code></pre>
    <h2>{`Deploying locally`}</h2>
    <p>{`Now we have a pretty powerful setup for deploying our Flutter app to both the App Store and Play Store.`}</p>
    <p>{`However, sometimes you wish to deploy locally. This can be handy if you wish to quickly process a release (your machine is likely faster than the GitHub Actions runner - and a lot cheaper).`}</p>
    <p>{`Just remember to update the `}<inlineCode parentName="p">{`version`}</inlineCode>{` in your `}<inlineCode parentName="p">{`pubspec.yaml`}</inlineCode>{` file before running the following commands.`}</p>
    <h3>{`Deploying to Play Store`}</h3>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "16"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk8"
            }}>{`cd`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` android `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`&&`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` fastlane release_play_store`}</span></span></span></code></pre>
    <h3>{`Deploying to App Store`}</h3>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "17"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk8"
            }}>{`cd`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` ios `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`&&`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` fastlane release_app_store`}</span></span></span></code></pre>
    <h2>{`Summary`}</h2>
    <p>{`Congratulations! You now have a fully automated CI/CD pipeline for your Flutter app. Every time you create a new release on GitHub, your app will be built and deployed to the App Store and Play Store.`}</p>
    <p>{`Additionally, you can swiftly build and deploy your app locally using Fastlane.`}</p>
    <p>{`Please let me know in the comments section if you have any questions or if you run into any issues. I'll do my best to help you out!`}</p>
    <h3>{`Credits`}</h3>
    <ul>
      <li parentName="ul">{`Thanks to `}<a parentName="li" {...{
          "href": "https://dev.to/danielgomezrico/how-to-build-flutter-apps-on-ci-with-fastlane-and-reuse-some-code-2np3"
        }}>{`Daniel Gomez`}</a>{` for providing inspiration for reusing Fastlane logic and general lanes for flutter`}</li>
      <li parentName="ul">{`Thanks to `}<a parentName="li" {...{
          "href": "https://medium.com/@manoelsrs/setting-up-a-ci-cd-pipeline-for-ios-using-fastlane-and-github-actions-in-a-flutter-project-8fd350237c33"
        }}>{`Manoel Soares Neto`}</a>{` for providing a detailed article about fastlane, match and the App Store Connect API keys`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      